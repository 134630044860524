import React, { MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';

import clsx from 'clsx';

import { useGeHiddenPages } from '../../../../api/teammates/hooks';
import { SubscriptionPlanEnum } from '../../../../api/user/types';
import SvgBilling from '../../../../assets/icons/Billing';
import SvgHelp from '../../../../assets/icons/Help';
import SvgLetterIcon from '../../../../assets/icons/LetterIcon';
import SvgLockIcon from '../../../../assets/icons/LockIcon';
import SvgLogout from '../../../../assets/icons/Logout';
import SvgNotifications from '../../../../assets/icons/Notifications';
import SvgPinIcon from '../../../../assets/icons/PinIcon';
import SvgProfile from '../../../../assets/icons/Profile';
import SvgShopIcon from '../../../../assets/icons/ShopIcon';
import SvgTeammates from '../../../../assets/icons/Teammates';
import { UpgradeSubscriptionTooltip } from '../../../../shared/components/upgrade-subscription-tooltip';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getIsAccountMenuOpened, getIsRestaurant, getMySubscriptionType, logoutThunk } from '../../../../store/user';
import { LinkItem, SelectedMenuDropDown } from '../left-nav-bar';
import CodeIcon from '@material-ui/icons/Code';

import { useRippleStyles } from '../../../../styles/customRipple';
import { useStyles } from './style';
import { useCurrentTheme } from '../../../../api/admin-themes/hooks';
import { ThemeColors } from '../../../../api/admin-themes/types';

interface Props {
  closeMenus: () => void;
}

export const supplierLinks: LinkItem[] = [
  { title: 'Company Profile & Settings', icon: <SvgProfile />, link: '/account/', disabled: false, key: null },
  { title: 'My Profile', icon: <SvgProfile />, link: '/account/profile', disabled: false, key: null },
  { title: 'Notifications', icon: <SvgNotifications />, link: '/account/notifications', disabled: false, key: null },
  { title: 'Teammates', icon: <SvgTeammates />, link: '/account/teammates', disabled: false, key: null },
  { title: 'Integrations', icon: <CodeIcon />, link: '/account/integrations', disabled: false, key: null },
  { title: 'Billing', icon: <SvgBilling />, link: '/account/billing', disabled: false, key: null },
  { title: 'Help', icon: <SvgHelp />, link: '/account/help', disabled: false, key: null },
];

export const customerLinks: LinkItem[] = [
  { title: 'Profile', icon: <SvgProfile />, link: '/account/', disabled: false, key: null },
  {
    title: 'Suppliers & Categories',
    link: '/account/suppliers',
    key: 4 as SelectedMenuDropDown.suppliers,
    icon: <SvgShopIcon />,
    permissionKey: 'supplier_and_categories',
    nestedLinks: [
      {
        title: 'Supplier list',
        link: '/account/suppliers',
        exact: true,
        showCounter: true,
        key: null,
      },
      {
        title: 'Add supplier',
        link: '/account/suppliers/add',
        key: null,
      },
      {
        title: 'Categories',
        link: '/account/suppliers/categories',
        key: null,
      },
    ],
  },
  { title: 'Venue Information', icon: <SvgPinIcon />, link: '/account/venue', disabled: false, key: null },
  { title: 'Teammates', icon: <SvgTeammates />, link: '/account/teammates', disabled: false, key: null, permissionKey: 'teammates' },
  { title: 'Notifications', icon: <SvgLetterIcon />, link: '/account/notifications', disabled: false, key: null },
  { title: 'Billing', icon: <SvgBilling />, link: '/account/billing', disabled: false, key: null, permissionKey: 'billing' },
  { title: 'Help', icon: <SvgHelp />, link: '/account/help', disabled: false, key: null },
  { title: 'Logout', icon: <SvgLogout />, link: '/login', disabled: false, key: null },
];

export const customerLinksNormal: LinkItem[] = [
  { title: 'Profile', icon: <SvgProfile />, link: '/account/', disabled: false, key: null },
  { title: 'Teammates', icon: <SvgTeammates />, link: '/account/teammates', disabled: false, key: null },
  { title: 'Venue Information', icon: <SvgPinIcon />, link: '/account/venue', disabled: false, key: null },
  { title: 'Email Notifications', icon: <SvgLetterIcon />, link: '/account/notifications', disabled: false, key: null },
  { title: 'Help', icon: <SvgHelp />, link: '/account/help', disabled: false, key: null },
  { title: 'Logout', icon: <SvgLogout />, link: '/login', disabled: false, key: null },
];

export const NestedAccountMenu: React.FC<Props> = ({ closeMenus }) => {
  const { colors } = useCurrentTheme();
  const classes = useStyles({ textColor: colors[ThemeColors.navBarText], bgColor: colors[ThemeColors.navBarBg] });
  const rippleClass = useRippleStyles();

  const dispatch = useAppDispatch();
  const hiddenLinks = useGeHiddenPages();

  const isAccountMenuOpened = useAppSelector(getIsAccountMenuOpened);
  const isRestaurant = useAppSelector(getIsRestaurant);
  const subscriptionType = useAppSelector(getMySubscriptionType);

  const logout = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    dispatch(logoutThunk());
  };

  const preventClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
  };

  return isAccountMenuOpened ? (
    <div className={classes.accountMenuBox}>
      {(isRestaurant
        ? subscriptionType === SubscriptionPlanEnum.NORMAL
          ? customerLinksNormal
          : customerLinks
        : [...supplierLinks, customerLinks[customerLinks.length - 1]]
      ).map(({ link, title, icon, disabled, permissionKey }) =>
        link === '/account/teammates' && !isRestaurant && subscriptionType === SubscriptionPlanEnum.FREE ? (
          <NavLink
            key={link}
            to={link}
            exact={true}
            onClick={preventClick}
            className={clsx(classes.accountLinkWrap, classes.disabled)}
            activeClassName={classes.accountLinkActive}
          >
            <div className={classes.accountLinkIcon}>{icon}</div>
            <span>{title}</span>
            <div className={classes.lockIconWrap}>
              <UpgradeSubscriptionTooltip text='Upgrade your subscription plan to unlock ‘Teammates’ options' placement={'bottom-end'}>
                <SvgLockIcon className={classes.lockIcon} />
              </UpgradeSubscriptionTooltip>
            </div>
          </NavLink>
        ) : (
          <NavLink
            key={link}
            to={link}
            exact={!link.includes('/account/billing')}
            onClick={
              (Array.isArray(hiddenLinks) && permissionKey && hiddenLinks.includes(permissionKey)) || disabled
                ? preventClick
                : title === 'Logout'
                ? logout
                : closeMenus
            }
            className={
              (Array.isArray(hiddenLinks) && permissionKey && hiddenLinks.includes(permissionKey)) || disabled
                ? clsx(classes.accountLinkWrap, classes.disabled)
                : clsx(classes.accountLinkWrap, rippleClass.ripple)
            }
            activeClassName={classes.accountLinkActive}
          >
            <div className={classes.accountLinkIcon}>{icon}</div>
            <span>{title}</span>
            {/*{title === 'Teammates' && !isRestaurant && <span className={classes.ordersCount}>8</span>}*/}
          </NavLink>
        ),
      )}
    </div>
  ) : null;
};
