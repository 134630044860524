import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { colorVariables } from '../../../../styles/colorVariables';
import { Sizes } from '../../../../styles/sizes';

export interface StyleProps {
  textColor?: string;
  bgColor?: string;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    topBar: {
      zIndex: 200,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: colorVariables.white,
      boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.1)',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      [theme.breakpoints.down(Sizes.mobile)]: {
        zIndex: 900,
      },
    },
    topBarCustomer: {
      zIndex: 900,
      backgroundColor: ({ bgColor }) => bgColor || colorVariables.navy,
    },
    accountStyle: {
      backgroundColor: ({ bgColor }) => bgColor || colorVariables.navy,
    },
    topBox: {
      padding: '10px 20px 10px 270px',
      height: 63,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down(Sizes.desktop)]: {
        height: 85,
        padding: '20px 40px 20px 120px',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        height: 50,
        padding: '10px 20px 10px 10px',
      },
    },
    topBoxCustomer: {
      position: 'relative',
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        height: 63,
        padding: '10px 20px 10px 270px',
      },
    },
    leftBox: {
      width: '55%',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        paddingTop: 20,
      },
    },
    rightBox: {
      width: '45%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      position: 'relative',
      height: '100%',
    },
    bottomBox: {
      borderTop: `1px solid ${colorVariables.steelGrey}`,
      padding: '10px 20px 10px 270px',
      width: '100%',
      height: 45,
      display: 'flex',
      [theme.breakpoints.down(Sizes.desktop)]: {
        padding: '20px 40px 20px 120px',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '10px 20px 10px 10px',
      },
    },
    withTopNavigation: {
      height: 75,
      paddingBottom: 0,
    },
    logo_link: {
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginLeft: 8,
      },
    },
    profileMenuWrap: {
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      overflow: 'hidden',
      position: 'relative',
      color: colorVariables.steelGrey,
      paddingRight: 20,
    },
    profileLogoWrap: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 32,
      height: 32,
      borderRadius: '50%',
      overflow: 'hidden',
      '& img': {
        height: '100%',
      },
    },
    profileText: {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '22px',
      color: colorVariables.steelGrey,
      textTransform: 'uppercase',
      marginRight: 15,
      textAlign: 'right',
    },
    linkWrap: {
      display: 'flex',
      width: '100%',
      fontSize: 14,
      padding: '8px 20px',
      color: colorVariables.blueGrey,
      '&:hover div': {
        opacity: 1,
      },
    },
    disabledSupplierSide: {
      color: `${colorVariables.grey80} !important`,
      cursor: 'default',
      pointerEvents: 'none',
      '& div': {
        opacity: 0.5,
      },
      '&:hover': {
        backgroundColor: 'white !important',
        color: `${colorVariables.grey80} !important`,
        '& div': {
          opacity: 0.5,
        },
      },
    },
    disabledRestaurantSide: {
      color: `${colorVariables.grey80} !important`,
      cursor: 'default',
      pointerEvents: 'none',
      '& div': {
        opacity: 0.5,
      },
      '&:hover': {
        color: `${colorVariables.grey80} !important`,
        '& div': {
          opacity: 0.5,
        },
      },
    },
    activeLink: {
      color: `${colorVariables.blue} !important`,
      backgroundColor: 'rgba(51, 168, 226, 0.05)',
      boxShadow: `0 0 0 300px rgba(51, 168, 226, 0.05)`,
    },
    imageWrap: {
      width: 20,
      height: 20,
      opacity: 0.6,
      marginRight: 18,
      fontSize: 20,
      '& svg': {
        color: colorVariables.grey60,
      },
    },
    logoActive: {
      boxShadow: `0 0 2px 1px ${colorVariables.green}`,
    },
    logoBox: {
      marginLeft: -180,
      width: 160,
      height: 30,
    },
    customerMenuTrigger: {
      padding: '0 15px',
      borderRadius: 4,
      height: 44,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&:hover': {
        backgroundColor: 'rgba(152, 165, 183, 0.15)',
      },
    },
    customerMenuTriggerDisabled: {
      backgroundColor: colorVariables.transparent,
      '&:hover': {
        cursor: 'default',
        backgroundColor: colorVariables.transparent,
      },
    },
    customerName: {
      textAlign: 'center',
      flex: 1,
      marginLeft: 15,
      marginRight: 15,
      color: ({ textColor }) => textColor || colorVariables.steelGrey,
    },
    arrow: {
      color: colorVariables.steelGrey,
      fontSize: 28,
      '&:hover': {
        color: colorVariables.grey60,
      },
    },
    customerArrow: {
      color: ({ textColor }) => textColor || colorVariables.steelGrey,
      fontSize: 20,
      '&:hover': {
        color: colorVariables.white,
      },
    },
    hamburgerCustomer: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      left: 30,
      [theme.breakpoints.down(Sizes.mobile)]: {
        display: 'none',
      },
    },
    largeHamburgerIcon: {
      cursor: 'pointer',
      '&:hover': {
        color: colorVariables.white,
      },
    },
    iconWrap: {
      marginRight: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 42,
      height: 42,
      borderRadius: '50%',
    },
    iconWrapActive: {
      backgroundColor: 'rgba(152, 165, 183, 0.25)',
    },
    customerBottomBox: {
      backgroundColor: colorVariables.white,
    },
    hidden: {
      display: 'none',
    },
    hamburgerWrap: {
      display: 'flex',
      alignItems: 'center',
      '&>:first-child': {
        '& svg': {
          color: ({ textColor }) => textColor || colorVariables.grey60,
        },
        transform: 'translateY(-2px)',
      },
    },
    link: {
      cursor: 'pointer',
    },
    lockWrap: {
      marginLeft: 'auto',
      width: 30,
      height: 20,
      transform: 'translateX(20px)',
      cursor: 'pointer',
    },
    backAdminBtn: {
      marginLeft: 60,
      [theme.breakpoints.down(Sizes.mobile)]: {
        marginLeft: 10,
        padding: 7,
      },
    },
    backAdminBtnHidden: {
      display: 'none',
    },
    hamburgerInactive: {
      cursor: 'default',
      opacity: 0.5,
      '&:hover': {
        cursor: 'default',
        opacity: 0.5,
      },
    },
    lockIconWrap: {
      height: 25,
      width: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      marginLeft: 'auto',
    },
  }),
);
