import * as React from 'react';

function SvgCaFlag(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="mask0_1742_68516" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="16">
        <rect y="0.5" width="20" height="15" fill="white" />
      </mask>
      <g mask="url(#mask0_1742_68516)">
        <path fillRule="evenodd" clipRule="evenodd" d="M5 0.5H15V15.5H5V0.5Z" fill="#F7FCFF" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.95558 5.75159L9.98314 4.25L10 13H9.57178L9.83451 10.8346C9.83451 10.8346 6.95223 11.364 7.19051 11.097C7.42879 10.83 7.56604 10.3401 7.56604 10.3401L5 8.59241C5 8.59241 5.40477 8.58723 5.73407 8.3873C6.06337 8.18738 5.40477 7.00168 5.40477 7.00168L6.70033 7.19409L7.19051 6.65029L8.16799 7.69462H8.60738L8.16799 5.3025L8.95558 5.75159ZM10 13V4.25L11.0444 5.75159L11.832 5.3025L11.3926 7.69462H11.832L12.8095 6.65029L13.2997 7.19409L14.5952 7.00168C14.5952 7.00168 13.9366 8.18738 14.2659 8.3873C14.5952 8.58723 15 8.59241 15 8.59241L12.434 10.3401C12.434 10.3401 12.5712 10.83 12.8095 11.097C13.0478 11.364 10.1655 10.8346 10.1655 10.8346L10.4282 13H10Z" fill="#E31D1C" />
        <path fillRule="evenodd" clipRule="evenodd" d="M15 0.5H20V15.5H15V0.5Z" fill="#E31D1C" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 0.5H5V15.5H0V0.5Z" fill="#E31D1C" />
      </g>
    </svg>
  );
}

export default SvgCaFlag;
