import * as React from 'react';

function SvgAuFlag(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="mask0_1742_68506" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="16">
        <rect y="0.5" width="20" height="15" fill="white" />
      </mask>
      <g mask="url(#mask0_1742_68506)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 0.5H20V15.5H0V0.5Z" fill="#2E42A5" />
        <rect y="0.5" width="11" height="9" fill="#2E42A5" />
        <mask id="mask1_1742_68506" maskUnits="userSpaceOnUse" x="0" y="0" width="11" height="10">
          <rect y="0.5" width="11" height="9" fill="white" />
        </mask>
        <g mask="url(#mask1_1742_68506)">
          <path d="M-1.25269 8.62482L1.22291 9.71057L11.3062 1.6804L12.612 0.0669403L9.96468 -0.295949L5.85197 3.16448L2.54165 5.496L-1.25269 8.62482Z" fill="#F7FCFF" />
          <path d="M-0.913818 9.38555L0.347383 10.0156L12.143 -0.0829468H10.3721L-0.913818 9.38555Z" fill="#F50100" />
          <path d="M12.5027 8.62493L10.3056 10.0206L-0.0561562 1.6805L-1.36198 0.0670462L1.28532 -0.295844L5.39803 3.16459L8.70835 5.49611L12.5027 8.62493Z" fill="#F7FCFF" />
          <path d="M12.4182 9.17082L11.157 9.8009L6.13431 5.47718L4.64518 4.9941L-1.48763 0.0725183H0.283246L6.41261 4.87728L8.04071 5.45655L12.4182 9.17082Z" fill="#F50100" />
          <mask id="path-9-outside-1_1742_68506" maskUnits="userSpaceOnUse" x="-1" y="-0.5" width="13" height="11" fill="black">
            <rect fill="white" x="-1" y="-0.5" width="13" height="11" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6 0.5H5V4.5H0V5.5H5V9.5H6V5.5H11V4.5H6V0.5Z" />
          </mask>
          <path fillRule="evenodd" clipRule="evenodd" d="M6 0.5H5V4.5H0V5.5H5V9.5H6V5.5H11V4.5H6V0.5Z" fill="#F50100" />
          <path d="M5 0.5V-0.4375H4.0625V0.5H5ZM6 0.5H6.9375V-0.4375H6V0.5ZM5 4.5V5.4375H5.9375V4.5H5ZM0 4.5V3.5625H-0.9375V4.5H0ZM0 5.5H-0.9375V6.4375H0V5.5ZM5 5.5H5.9375V4.5625H5V5.5ZM5 9.5H4.0625V10.4375H5V9.5ZM6 9.5V10.4375H6.9375V9.5H6ZM6 5.5V4.5625H5.0625V5.5H6ZM11 5.5V6.4375H11.9375V5.5H11ZM11 4.5H11.9375V3.5625H11V4.5ZM6 4.5H5.0625V5.4375H6V4.5ZM5 1.4375H6V-0.4375H5V1.4375ZM5.9375 4.5V0.5H4.0625V4.5H5.9375ZM0 5.4375H5V3.5625H0V5.4375ZM0.9375 5.5V4.5H-0.9375V5.5H0.9375ZM5 4.5625H0V6.4375H5V4.5625ZM5.9375 9.5V5.5H4.0625V9.5H5.9375ZM6 8.5625H5V10.4375H6V8.5625ZM5.0625 5.5V9.5H6.9375V5.5H5.0625ZM11 4.5625H6V6.4375H11V4.5625ZM10.0625 4.5V5.5H11.9375V4.5H10.0625ZM6 5.4375H11V3.5625H6V5.4375ZM5.0625 0.5V4.5H6.9375V0.5H5.0625Z" fill="#F7FCFF" mask="url(#path-9-outside-1_1742_68506)" />
        </g>
      </g>
      <g clipPath="url(#clip0_1742_68506)">
        <path d="M5.92003 12.7784L5.09999 13.5362L5.1812 12.4226L4.07742 12.2539L4.99872 11.6231L4.44237 10.655L5.51001 10.9819L5.92003 9.94336L6.33005 10.9819L7.39769 10.655L6.84134 11.6231L7.76264 12.2539L6.65886 12.4226L6.74007 13.5362L5.92003 12.7784Z" fill="#F7FCFF" />
        <path d="M13.24 8.79662L12.6933 9.30184L12.7474 8.55941L12.0116 8.44699L12.6258 8.02643L12.2549 7.38102L12.9666 7.59901L13.24 6.90662L13.5133 7.59901L14.2251 7.38102L13.8542 8.02643L14.4684 8.44699L13.7325 8.55941L13.7867 9.30184L13.24 8.79662Z" fill="#F7FCFF" />
        <path d="M14.52 3.46336L13.9733 3.96859L14.0275 3.22616L13.2916 3.11374L13.9058 2.69318L13.5349 2.04777L14.2467 2.26575L14.52 1.57336L14.7934 2.26575L15.5051 2.04777L15.1342 2.69318L15.7484 3.11374L15.0126 3.22616L15.0667 3.96859L14.52 3.46336Z" fill="#F7FCFF" />
        <path d="M17.72 6.12999L17.1733 6.63521L17.2274 5.89279L16.4916 5.78037L17.1058 5.3598L16.7349 4.71439L17.4466 4.93238L17.72 4.23999L17.9933 4.93238L18.7051 4.71439L18.3342 5.3598L18.9484 5.78037L18.2125 5.89279L18.2667 6.63521L17.72 6.12999Z" fill="#F7FCFF" />
        <path d="M15.1599 13.7966L14.6132 14.3018L14.6673 13.5594L13.9315 13.447L14.5457 13.0264L14.1748 12.381L14.8866 12.599L15.1599 11.9066L15.4332 12.599L16.145 12.381L15.7741 13.0264L16.3883 13.447L15.6525 13.5594L15.7066 14.3018L15.1599 13.7966Z" fill="#F7FCFF" />
        <path d="M17.4001 9.63918L16.8446 9.9312L16.9507 9.31269L16.5013 8.87466L17.1223 8.78442L17.4001 8.22168L17.6778 8.78442L18.2988 8.87466L17.8495 9.31269L17.9555 9.9312L17.4001 9.63918Z" fill="#F7FCFF" />
      </g>
      <defs>
        <clipPath id="clip0_1742_68506">
          <rect width="15" height="13" fill="white" transform="translate(4 1.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAuFlag;
