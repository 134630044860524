import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import clsx from 'clsx';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { useMeQuery, useSendContactMsgMutation } from '../../../../api/user';
import { BackButton } from '../../../../shared/components/back-button';
import { ThemedButton } from '../../../../shared/components/themed-button';
import { FormikInput } from '../../../../shared/components/formik-input';
import { NavbarTitle } from '../../../../shared/components/navbar-title';
import { useScreenSize } from '../../../../shared/hooks/use-screen-size';
import { LocalStorageService } from '../../../../shared/services/localStorage.service';
import { useAppSelector } from '../../../../store';
import { getIsRestaurant } from '../../../../store/user';
import { TopNavBar } from '../../../AppDrawer/MainNavBar/top-nav-bar';
import OpLogo from '../../../../assets/images/logo/Logo_Small_Dark.svg';

import { useStyles } from './style';
import SvgAuFlag from '../../../../assets/icons/AuFlag';
import SvgUsFlag from '../../../../assets/icons/UsFlag';
import SvgCaFlag from '../../../../assets/icons/CaFlag';

interface Values {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  help_subject: string;
  help_message: string;
}

export const ContactUs: React.FC = () => {
  const classes = useStyles();
  const { goBack } = useHistory();
  const ref = useRef<HTMLFormElement | null>(null);
  const { isMobile } = useScreenSize();

  const isRestaurant = useAppSelector(getIsRestaurant);
  const isAu = LocalStorageService.getItem('country') === 'AU';

  const [sendMsg, { isLoading }] = useSendContactMsgMutation();
  const { data: user } = useMeQuery();

  const contactSchema = Yup.object().shape({
    first_name: Yup.string().required('Required field'),
    last_name: Yup.string().required('Required field'),
    email: Yup.string().required('Required field').email('Enter correct email!'),
    phone_number: Yup.string().required('Required field'),
    help_subject: Yup.string().required('Required field'),
    help_message: Yup.string().required('Required field'),
  });

  const onSubmit = (values: Values, formikHelpers: FormikHelpers<Values>) => {
    sendMsg({
      user: values,
    });
    formikHelpers.setSubmitting(false);
  };

  return (
    <>
      <TopNavBar
        isAccount={true}
        leftComponent={<NavbarTitle title='Help' showBackBtn={true} />}
        bottomComponent={isMobile ? <NavbarTitle title='Help' showBackBtn={true} /> : undefined}
      />
      <div className={classes.root}>
        <BackButton onClickHandler={goBack} />
        <div className={classes.title}>We are here for you</div>
        <div className={classes.subTitle}>Get in touch</div>
        <Formik
          initialValues={{
            first_name: user?.first_name || '',
            last_name: user?.last_name || '',
            phone_number: user?.phone_number || '',
            email: user?.email || '',
            help_subject: '',
            help_message: '',
          }}
          onSubmit={onSubmit}
          validationSchema={contactSchema}
        >
          {({ submitForm }) => (
            <Form className={classes.formBox} ref={ref}>
              <div className={classes.fieldsBox}>
                <div className={clsx(classes.fieldWrap, isRestaurant && classes.fieldWrapCustomer)}>
                  <Field name='first_name'>
                    {(fieldProps: FieldProps) => <FormikInput {...fieldProps} label='First Name *' placeholder='First Name' />}
                  </Field>
                </div>
                <div className={clsx(classes.fieldWrap, isRestaurant && classes.fieldWrapCustomer)}>
                  <Field name='last_name'>
                    {(fieldProps: FieldProps) => <FormikInput {...fieldProps} label='Last Name *' placeholder='Last Name' />}
                  </Field>
                </div>
              </div>
              <div className={classes.fieldsBox}>
                <div className={clsx(classes.fieldWrap, isRestaurant && classes.fieldWrapCustomer)}>
                  <Field name='email'>
                    {(fieldProps: FieldProps) => <FormikInput {...fieldProps} label='Email Address *' placeholder='Email Address' />}
                  </Field>
                </div>
                <div className={clsx(classes.fieldWrap, isRestaurant && classes.fieldWrapCustomer)}>
                  <Field name='phone_number'>
                    {(fieldProps: FieldProps) => (
                      <FormikInput {...fieldProps} type='number' label='Phone Number *' placeholder='Phone Number ' />
                    )}
                  </Field>
                </div>
                <div className={clsx(classes.fieldWrap, isRestaurant && classes.fieldWrapCustomer)}>
                  <Field name='help_subject'>
                    {(fieldProps: FieldProps) => <FormikInput {...fieldProps} label='Subject *' placeholder='Subject' />}
                  </Field>
                </div>
              </div>
              <div className={classes.fullWidthFieldWrap}>
                <Field
                  multiline={true}
                  minRows={4}
                  maxRows={4}
                  component={FormikInput}
                  name='help_message'
                  label='Message *'
                  placeholder='Please enter your inquiry here'
                />
              </div>
              <div className={classes.formFooter}>
                <ThemedButton title='Send Message' onClick={submitForm} width={280} customClass={classes.sendButton} disabled={isLoading} />
                <div className={classes.contactBox}>
                  <div className={classes.row}>
                    <span className={classes.contactBoxTitle}>Or contact us on</span>
                    {isMobile && <div className={classes.newLine} />}
                    <img src={OpLogo} alt='Open Pantry' className={classes.opLogo} />
                    <span className={classes.supportText}>Customer Support</span>
                    <span><a className={classes.emailLink} href='mailto:info@theopenpantry.com'>info@theopenpantry.com</a></span>
                  </div>
                  <div className={clsx(classes.row, classes.rowBorder)}>
                    <SvgAuFlag />
                    <span className={classes.contactBoxTitle}>Australia Head Office</span>
                    {isMobile && <div className={classes.newLine} />}
                    <span className={classes.phoneText}>Phone number</span>
                    <span><a href='tel:03 9020 9266'>03 9020 9266</a></span>
                  </div>
                  <div className={clsx(classes.row, classes.rowBorder)}>
                    <SvgUsFlag />
                    <span className={classes.contactBoxTitle}>USA Office Head Office</span>
                    {isMobile && <div className={classes.newLine} />}
                    <span className={classes.phoneText}>Phone number</span>
                    <span><a href='tel:+1 (929) 556 44 69'>+1 (929) 556 44 69</a></span>
                  </div>
                  <div className={clsx(classes.row, classes.rowBorder)}>
                    <SvgCaFlag />
                    <span className={classes.contactBoxTitle}>Canada Head Office</span>
                    {isMobile && <div className={classes.newLine} />}
                    <span className={classes.phoneText}>Phone number</span>
                    <span><a href='tel:+1 (647) 905 09 53'>+1 (647) 905 09 53</a></span>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
